
// Generated by peggy v. 2.0.1 (ts-pegjs plugin v. 3.1.0 )
//
// https://peggyjs.org/   https://github.com/metadevpro/ts-pegjs

"use strict";

export interface FilePosition {
  offset: number;
  line: number;
  column: number;
}

export interface FileRange {
  start: FilePosition;
  end: FilePosition;
  source: string;
}

export interface LiteralExpectation {
  type: "literal";
  text: string;
  ignoreCase: boolean;
}

export interface ClassParts extends Array<string | ClassParts> {}

export interface ClassExpectation {
  type: "class";
  parts: ClassParts;
  inverted: boolean;
  ignoreCase: boolean;
}

export interface AnyExpectation {
  type: "any";
}

export interface EndExpectation {
  type: "end";
}

export interface OtherExpectation {
  type: "other";
  description: string;
}

export type Expectation = LiteralExpectation | ClassExpectation | AnyExpectation | EndExpectation | OtherExpectation;

function peg$padEnd(str: string, targetLength: number, padString: string) {
  padString = padString || ' ';
  if (str.length > targetLength) {
    return str;
  }
  targetLength -= str.length;
  padString += padString.repeat(targetLength);
  return str + padString.slice(0, targetLength);
}

export class PeggySyntaxError extends Error {
  public static buildMessage(expected: Expectation[], found: string | null) {
    function hex(ch: string): string {
      return ch.charCodeAt(0).toString(16).toUpperCase();
    }

    function literalEscape(s: string): string {
      return s
        .replace(/\\/g, "\\\\")
        .replace(/"/g,  "\\\"")
        .replace(/\0/g, "\\0")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/[\x00-\x0F]/g,            (ch) => "\\x0" + hex(ch) )
        .replace(/[\x10-\x1F\x7F-\x9F]/g, (ch) => "\\x"  + hex(ch) );
    }

    function classEscape(s: string): string {
      return s
        .replace(/\\/g, "\\\\")
        .replace(/\]/g, "\\]")
        .replace(/\^/g, "\\^")
        .replace(/-/g,  "\\-")
        .replace(/\0/g, "\\0")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/[\x00-\x0F]/g,            (ch) => "\\x0" + hex(ch) )
        .replace(/[\x10-\x1F\x7F-\x9F]/g, (ch) => "\\x"  + hex(ch) );
    }

    function describeExpectation(expectation: Expectation) {
      switch (expectation.type) {
        case "literal":
          return "\"" + literalEscape(expectation.text) + "\"";
        case "class":
          const escapedParts = expectation.parts.map((part) => {
            return Array.isArray(part)
              ? classEscape(part[0] as string) + "-" + classEscape(part[1] as string)
              : classEscape(part);
          });

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        case "any":
          return "any character";
        case "end":
          return "end of input";
        case "other":
          return expectation.description;
      }
    }

    function describeExpected(expected1: Expectation[]) {
      const descriptions = expected1.map(describeExpectation);
      let i: number;
      let j: number;

      descriptions.sort();

      if (descriptions.length > 0) {
        for (i = 1, j = 1; i < descriptions.length; i++) {
          if (descriptions[i - 1] !== descriptions[i]) {
            descriptions[j] = descriptions[i];
            j++;
          }
        }
        descriptions.length = j;
      }

      switch (descriptions.length) {
        case 1:
          return descriptions[0];

        case 2:
          return descriptions[0] + " or " + descriptions[1];

        default:
          return descriptions.slice(0, -1).join(", ")
            + ", or "
            + descriptions[descriptions.length - 1];
      }
    }

    function describeFound(found1: string | null) {
      return found1 ? "\"" + literalEscape(found1) + "\"" : "end of input";
    }

    return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
  }

  public message: string;
  public expected: Expectation[];
  public found: string | null;
  public location: FileRange;
  public name: string;

  constructor(message: string, expected: Expectation[], found: string | null, location: FileRange) {
    super();
    this.message = message;
    this.expected = expected;
    this.found = found;
    this.location = location;
    this.name = "PeggySyntaxError";

    if (typeof (Object as any).setPrototypeOf === "function") {
      (Object as any).setPrototypeOf(this, PeggySyntaxError.prototype);
    } else {
      (this as any).__proto__ = PeggySyntaxError.prototype;
    }
    if (typeof (Error as any).captureStackTrace === "function") {
      (Error as any).captureStackTrace(this, PeggySyntaxError);
    }
  }

  format(sources: { grammarSource?: string; text: string }[]): string {
    let str = 'Error: ' + this.message;
    if (this.location) {
      let src: string[] | null = null;
      let k;
      for (k = 0; k < sources.length; k++) {
        if (sources[k].grammarSource === this.location.source) {
          src = sources[k].text.split(/\r\n|\n|\r/g);
          break;
        }
      }
      let s = this.location.start;
      let loc = this.location.source + ':' + s.line + ':' + s.column;
      if (src) {
        let e = this.location.end;
        let filler = peg$padEnd('', s.line.toString().length, ' ');
        let line = src[s.line - 1];
        let last = s.line === e.line ? e.column : line.length + 1;
        str += '\n --> ' + loc + '\n' + filler + ' |\n' + s.line + ' | ' + line + '\n' + filler + ' | ' +
          peg$padEnd('', s.column - 1, ' ') +
          peg$padEnd('', last - s.column, '^');
      } else {
        str += '\n at ' + loc;
      }
    }
    return str;
  }
}

function peg$parse(input: string, options?: ParseOptions) {
  options = options !== undefined ? options : {};

  const peg$FAILED: Readonly<any> = {};
  const peg$source = options.grammarSource;

  const peg$startRuleFunctions: {[id: string]: any} = { start: peg$parsestart };
  let peg$startRuleFunction: () => any = peg$parsestart;

  const peg$c0 = function(lhs: any, rhs: any): any {
          if (rhs && rhs.length) {
              var exp = lhs, i = 0;
              for (; i < rhs.length; i++) {
                  if (rhs[i].op !== undefined) {
                      exp = {type: "binaryOp", operator: rhs[i].op, lhs: exp, rhs: rhs[i].rhs};
                  }
              }
              return exp;
          } else {
              return lhs;
          }
      };
  const peg$c1 = function(op: any, rhs: any): any { return {op: op, rhs: rhs}; };
  const peg$c2 = function(v: any): any { return {order: "random", fraction: v}; };
  const peg$c3 = function(v: any): any { return {order: "random", count: v}; };
  const peg$c4 = function(t: any, v: any): any { return {order: t, fraction: v}; };
  const peg$c5 = function(t: any, v: any): any { return {order: t, count: v}; };
  const peg$c6 = "limit";
  const peg$c7 = peg$literalExpectation("limit", false);
  const peg$c8 = "random";
  const peg$c9 = peg$literalExpectation("random", false);
  const peg$c10 = "highest value";
  const peg$c11 = peg$literalExpectation("highest value", false);
  const peg$c12 = "lowest value";
  const peg$c13 = peg$literalExpectation("lowest value", false);
  const peg$c14 = "highest future value";
  const peg$c15 = peg$literalExpectation("highest future value", false);
  const peg$c16 = "lowest future value";
  const peg$c17 = peg$literalExpectation("lowest future value", false);
  const peg$c18 = "most engaged";
  const peg$c19 = peg$literalExpectation("most engaged", false);
  const peg$c20 = "least engaged";
  const peg$c21 = peg$literalExpectation("least engaged", false);
  const peg$c22 = "most recently purchased";
  const peg$c23 = peg$literalExpectation("most recently purchased", false);
  const peg$c24 = "most recently engaged";
  const peg$c25 = peg$literalExpectation("most recently engaged", false);
  const peg$c26 = "most frequently purchased";
  const peg$c27 = peg$literalExpectation("most frequently purchased", false);
  const peg$c28 = "most frequently engaged";
  const peg$c29 = peg$literalExpectation("most frequently engaged", false);
  const peg$c30 = peg$otherExpectation("Boolean operator");
  const peg$c31 = peg$otherExpectation("and");
  const peg$c32 = "and";
  const peg$c33 = peg$literalExpectation("and", false);
  const peg$c34 = "&&";
  const peg$c35 = peg$literalExpectation("&&", false);
  const peg$c36 = "intersect";
  const peg$c37 = peg$literalExpectation("intersect", false);
  const peg$c38 = "&";
  const peg$c39 = peg$literalExpectation("&", false);
  const peg$c40 = function(): any { return "and"; };
  const peg$c41 = peg$otherExpectation("or");
  const peg$c42 = "or";
  const peg$c43 = peg$literalExpectation("or", false);
  const peg$c44 = "||";
  const peg$c45 = peg$literalExpectation("||", false);
  const peg$c46 = "union";
  const peg$c47 = peg$literalExpectation("union", false);
  const peg$c48 = "|";
  const peg$c49 = peg$literalExpectation("|", false);
  const peg$c50 = function(): any { return "or"; };
  const peg$c51 = peg$otherExpectation("except");
  const peg$c52 = "except";
  const peg$c53 = peg$literalExpectation("except", false);
  const peg$c54 = "-";
  const peg$c55 = peg$literalExpectation("-", false);
  const peg$c56 = function(): any { return "except"; };
  const peg$c57 = function(op: any, rhs: any): any { return {type: "unaryOp", operator: "not", rhs: rhs}; };
  const peg$c58 = peg$otherExpectation("not");
  const peg$c59 = "not ";
  const peg$c60 = peg$literalExpectation("not ", false);
  const peg$c61 = "~";
  const peg$c62 = peg$literalExpectation("~", false);
  const peg$c63 = "!";
  const peg$c64 = peg$literalExpectation("!", false);
  const peg$c65 = function(atom: any, limit: any): any { atom.limit = limit; return atom; };
  const peg$c66 = "(";
  const peg$c67 = peg$literalExpectation("(", false);
  const peg$c68 = ")";
  const peg$c69 = peg$literalExpectation(")", false);
  const peg$c70 = function(expr: any): any { return expr; };
  const peg$c71 = function(field: any, op: any, value: any): any { return {type: "fieldPredicate", field: field, operator: op === "is" ? "isDateRange" : "isNotDateRange", value: value}; };
  const peg$c72 = function(field: any, op: any, value: any): any { return {type: "fieldPredicate", field: field, operator: op, value: value}; };
  const peg$c73 = function(op: any, ids: any, option: any): any { return {type: "predicate", resource: "lists", event: op, id: ids, range: option || "all time", fields: []}; };
  const peg$c74 = function(op: any, ids: any, f: any, date: any): any { return {type: "predicate", resource: "lists", event: op, id: ids, range: date || "all time", fields: f || []}; };
  const peg$c75 = "clicked activities links";
  const peg$c76 = peg$literalExpectation("clicked activities links", false);
  const peg$c77 = "clicked activity links";
  const peg$c78 = peg$literalExpectation("clicked activity links", false);
  const peg$c79 = "clicked activity link";
  const peg$c80 = peg$literalExpectation("clicked activity link", false);
  const peg$c81 = function(ids: any, refs: any, date: any, aggregate: any): any { return {type: "predicate", resource: "links", event: "clicked", operands: [{id: ids}, {ref: refs}], range: date || "all time", aggregate: aggregate || null}; };
  const peg$c82 = "clicked links";
  const peg$c83 = peg$literalExpectation("clicked links", false);
  const peg$c84 = "clicked link";
  const peg$c85 = peg$literalExpectation("clicked link", false);
  const peg$c86 = function(refs: any, ids: any, date: any, aggregate: any): any { return {type: "predicate", resource: "links", event: "clicked", operands: [{id: ids || null}, {ref: refs}], range: date || "all time", aggregate: aggregate || null}; };
  const peg$c87 = "clicked link starts with";
  const peg$c88 = peg$literalExpectation("clicked link starts with", false);
  const peg$c89 = "match views";
  const peg$c90 = peg$literalExpectation("match views", false);
  const peg$c91 = "match view";
  const peg$c92 = peg$literalExpectation("match view", false);
  const peg$c93 = function(ids: any, withParam: any): any { return {type: "predicate", resource: "views", event: "match", id: ids, "with": withParam || null}; };
  const peg$c94 = "subscribed lists";
  const peg$c95 = peg$literalExpectation("subscribed lists", false);
  const peg$c96 = "subscribed list";
  const peg$c97 = peg$literalExpectation("subscribed list", false);
  const peg$c98 = function(): any { return "subscribed"; };
  const peg$c99 = "unsubscribed lists";
  const peg$c100 = peg$literalExpectation("unsubscribed lists", false);
  const peg$c101 = "unsubscribed list";
  const peg$c102 = peg$literalExpectation("unsubscribed list", false);
  const peg$c103 = function(): any { return "unsubscribed"; };
  const peg$c104 = " ";
  const peg$c105 = peg$literalExpectation(" ", false);
  const peg$c106 = "any";
  const peg$c107 = peg$literalExpectation("any", false);
  const peg$c108 = function(event: any, date: any, aggregate: any): any { return {type: "predicate", resource: "any", event: event, range: date || "all time", id: "*", aggregate: aggregate || null}; };
  const peg$c109 = function(event: any, type: any, ids: any, date: any, aggregate: any): any { return {type: "predicate", resource: type, event: event, range: date || "all time", id: ids, aggregate: aggregate || null}; };
  const peg$c110 = function(event: any, activityType: any, date: any, aggregate: any): any { return {type: "predicate", resource: "activityType", event: event, range: date || "all time", ref: activityType, aggregate: aggregate || null}; };
  const peg$c111 = peg$otherExpectation("field comparison operator");
  const peg$c112 = "=";
  const peg$c113 = peg$literalExpectation("=", false);
  const peg$c114 = "!=";
  const peg$c115 = peg$literalExpectation("!=", false);
  const peg$c116 = ">=";
  const peg$c117 = peg$literalExpectation(">=", false);
  const peg$c118 = ">";
  const peg$c119 = peg$literalExpectation(">", false);
  const peg$c120 = "<=";
  const peg$c121 = peg$literalExpectation("<=", false);
  const peg$c122 = "<";
  const peg$c123 = peg$literalExpectation("<", false);
  const peg$c124 = "like";
  const peg$c125 = peg$literalExpectation("like", false);
  const peg$c126 = "not like";
  const peg$c127 = peg$literalExpectation("not like", false);
  const peg$c128 = "ilike";
  const peg$c129 = peg$literalExpectation("ilike", false);
  const peg$c130 = "not ilike";
  const peg$c131 = peg$literalExpectation("not ilike", false);
  const peg$c132 = "is not";
  const peg$c133 = peg$literalExpectation("is not", false);
  const peg$c134 = "is";
  const peg$c135 = peg$literalExpectation("is", false);
  const peg$c136 = "one of";
  const peg$c137 = peg$literalExpectation("one of", false);
  const peg$c138 = "not one of";
  const peg$c139 = peg$literalExpectation("not one of", false);
  const peg$c140 = "~*";
  const peg$c141 = peg$literalExpectation("~*", false);
  const peg$c142 = "!~*";
  const peg$c143 = peg$literalExpectation("!~*", false);
  const peg$c144 = "!~";
  const peg$c145 = peg$literalExpectation("!~", false);
  const peg$c146 = "@@";
  const peg$c147 = peg$literalExpectation("@@", false);
  const peg$c148 = "!@@";
  const peg$c149 = peg$literalExpectation("!@@", false);
  const peg$c150 = peg$otherExpectation("field date range operator");
  const peg$c151 = "activity";
  const peg$c152 = peg$literalExpectation("activity", false);
  const peg$c153 = "activities";
  const peg$c154 = peg$literalExpectation("activities", false);
  const peg$c155 = function(ids: any): any { return ids; };
  const peg$c156 = peg$otherExpectation("event name");
  const peg$c157 = "sent";
  const peg$c158 = peg$literalExpectation("sent", false);
  const peg$c159 = "clicked";
  const peg$c160 = peg$literalExpectation("clicked", false);
  const peg$c161 = "opened";
  const peg$c162 = peg$literalExpectation("opened", false);
  const peg$c163 = "subscribed";
  const peg$c164 = peg$literalExpectation("subscribed", false);
  const peg$c165 = "unsubscribed";
  const peg$c166 = peg$literalExpectation("unsubscribed", false);
  const peg$c167 = "updated";
  const peg$c168 = peg$literalExpectation("updated", false);
  const peg$c169 = "forwarded";
  const peg$c170 = peg$literalExpectation("forwarded", false);
  const peg$c171 = "replied";
  const peg$c172 = peg$literalExpectation("replied", false);
  const peg$c173 = "viewed";
  const peg$c174 = peg$literalExpectation("viewed", false);
  const peg$c175 = "entered";
  const peg$c176 = peg$literalExpectation("entered", false);
  const peg$c177 = "referred";
  const peg$c178 = peg$literalExpectation("referred", false);
  const peg$c179 = "converted";
  const peg$c180 = peg$literalExpectation("converted", false);
  const peg$c181 = "bounced";
  const peg$c182 = peg$literalExpectation("bounced", false);
  const peg$c183 = "liked";
  const peg$c184 = peg$literalExpectation("liked", false);
  const peg$c185 = "abandoned";
  const peg$c186 = peg$literalExpectation("abandoned", false);
  const peg$c187 = function(): any { return "activities"; };
  const peg$c188 = "campaigns";
  const peg$c189 = peg$literalExpectation("campaigns", false);
  const peg$c190 = "campaign";
  const peg$c191 = peg$literalExpectation("campaign", false);
  const peg$c192 = function(): any { return "campaigns"; };
  const peg$c193 = "revisions";
  const peg$c194 = peg$literalExpectation("revisions", false);
  const peg$c195 = "revision";
  const peg$c196 = peg$literalExpectation("revision", false);
  const peg$c197 = function(): any { return "revisions"; };
  const peg$c198 = "configurations";
  const peg$c199 = peg$literalExpectation("configurations", false);
  const peg$c200 = "configuration";
  const peg$c201 = peg$literalExpectation("configuration", false);
  const peg$c202 = function(): any { return "configurations"; };
  const peg$c203 = peg$otherExpectation("activity type");
  const peg$c204 = "email";
  const peg$c205 = peg$literalExpectation("email", false);
  const peg$c206 = "sms";
  const peg$c207 = peg$literalExpectation("sms", false);
  const peg$c208 = "whatsapp";
  const peg$c209 = peg$literalExpectation("whatsapp", false);
  const peg$c210 = "web";
  const peg$c211 = peg$literalExpectation("web", false);
  const peg$c212 = "displayad";
  const peg$c213 = peg$literalExpectation("displayad", false);
  const peg$c214 = "line";
  const peg$c215 = peg$literalExpectation("line", false);
  const peg$c216 = "pushnotification";
  const peg$c217 = peg$literalExpectation("pushnotification", false);
  const peg$c218 = peg$otherExpectation("count aggregate");
  const peg$c219 = "count";
  const peg$c220 = peg$literalExpectation("count", false);
  const peg$c221 = function(op: any, value: any): any { return ["count", op, value]; };
  const peg$c222 = function(d: any): any { return d; };
  const peg$c223 = "with";
  const peg$c224 = peg$literalExpectation("with", false);
  const peg$c225 = function(w: any): any { return w; };
  const peg$c226 = "birthday";
  const peg$c227 = peg$literalExpectation("birthday", false);
  const peg$c228 = function(date: any): any { return {type: "birthdayPredicate", range: date }; };
  const peg$c229 = function(field: any, otherFields: any): any { return [field].concat(otherFields || []); };
  const peg$c230 = function(field: any, op: any, value: any): any { return {field: field, operator: op === "is" ? "isDateRange" : "isNotDateRange", value: value}; };
  const peg$c231 = function(field: any, op: any, value: any): any { return {field: field, operator: op, value: value}; };
  const peg$c232 = ",";
  const peg$c233 = peg$literalExpectation(",", false);
  const peg$c234 = "purchased";
  const peg$c235 = peg$literalExpectation("purchased", false);
  const peg$c236 = function(f: any, date: any, aggregate: any): any {
          return {
              type: "purchasePredicate",
              fields: f || [],
              range: date || "all time",
              aggregate: aggregate || null
          };
      };
  const peg$c237 = "visited";
  const peg$c238 = peg$literalExpectation("visited", false);
  const peg$c239 = function(f: any, date: any, aggregate: any): any {
          return {
              type: "visitPredicate",
              event: "visit",
              fields: f || [],
              range: date || "all time",
              aggregate: aggregate || null
          };
      };
  const peg$c240 = "tracked";
  const peg$c241 = peg$literalExpectation("tracked", false);
  const peg$c242 = function(e: any, f: any, date: any, aggregate: any): any {
          return {
              type: "visitPredicate",
              event: e,
              fields: f || [],
              range: date || "all time",
              aggregate: aggregate || null
          };
      };
  const peg$c243 = peg$otherExpectation("event type");
  const peg$c244 = "open";
  const peg$c245 = peg$literalExpectation("open", false);
  const peg$c246 = "view";
  const peg$c247 = peg$literalExpectation("view", false);
  const peg$c248 = "abandon";
  const peg$c249 = peg$literalExpectation("abandon", false);
  const peg$c250 = "submit";
  const peg$c251 = peg$literalExpectation("submit", false);
  const peg$c252 = "update";
  const peg$c253 = peg$literalExpectation("update", false);
  const peg$c254 = "enter";
  const peg$c255 = peg$literalExpectation("enter", false);
  const peg$c256 = "partition";
  const peg$c257 = peg$literalExpectation("partition", false);
  const peg$c258 = function(f: any): any {
          return {
              type: "partitionPredicate",
              fields: f || [],
          };
      };
  const peg$c259 = "contactable";
  const peg$c260 = peg$literalExpectation("contactable", false);
  const peg$c261 = "via";
  const peg$c262 = peg$literalExpectation("via", false);
  const peg$c263 = function(channel: any): any { return {type: "contactablePredicate", channel: channel }; };
  const peg$c264 = peg$otherExpectation("contact channel");
  const peg$c265 = /^[a-zA-Z_]/;
  const peg$c266 = peg$classExpectation([["a", "z"], ["A", "Z"], "_"], false, false);
  const peg$c267 = function(str: any): any { return str.join(""); };
  const peg$c268 = peg$otherExpectation("profile field");
  const peg$c269 = /^[a-zA-Z0-9_:]/;
  const peg$c270 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], "_", ":"], false, false);
  const peg$c271 = function(str: any): any { return str[0] + str[1].join(""); };
  const peg$c272 = "'";
  const peg$c273 = peg$literalExpectation("'", false);
  const peg$c274 = /^[^']/;
  const peg$c275 = peg$classExpectation(["'"], true, false);
  const peg$c276 = peg$otherExpectation("custom field");
  const peg$c277 = "cf:";
  const peg$c278 = peg$literalExpectation("cf:", false);
  const peg$c279 = function(field: any): any { return "cf:" + field; };
  const peg$c280 = peg$otherExpectation("subscriber field");
  const peg$c281 = "next";
  const peg$c282 = peg$literalExpectation("next", false);
  const peg$c283 = "last";
  const peg$c284 = peg$literalExpectation("last", false);
  const peg$c285 = "hour";
  const peg$c286 = peg$literalExpectation("hour", false);
  const peg$c287 = "day";
  const peg$c288 = peg$literalExpectation("day", false);
  const peg$c289 = "week";
  const peg$c290 = peg$literalExpectation("week", false);
  const peg$c291 = "month";
  const peg$c292 = peg$literalExpectation("month", false);
  const peg$c293 = "year";
  const peg$c294 = peg$literalExpectation("year", false);
  const peg$c295 = "hours";
  const peg$c296 = peg$literalExpectation("hours", false);
  const peg$c297 = "days";
  const peg$c298 = peg$literalExpectation("days", false);
  const peg$c299 = "weeks";
  const peg$c300 = peg$literalExpectation("weeks", false);
  const peg$c301 = "months";
  const peg$c302 = peg$literalExpectation("months", false);
  const peg$c303 = "years";
  const peg$c304 = peg$literalExpectation("years", false);
  const peg$c305 = peg$otherExpectation("date range");
  const peg$c306 = "yesterday";
  const peg$c307 = peg$literalExpectation("yesterday", false);
  const peg$c308 = "today";
  const peg$c309 = peg$literalExpectation("today", false);
  const peg$c310 = "tomorrow";
  const peg$c311 = peg$literalExpectation("tomorrow", false);
  const peg$c312 = "this week";
  const peg$c313 = peg$literalExpectation("this week", false);
  const peg$c314 = "this month";
  const peg$c315 = peg$literalExpectation("this month", false);
  const peg$c316 = "this year";
  const peg$c317 = peg$literalExpectation("this year", false);
  const peg$c318 = function(t: any): any { return t.join(""); };
  const peg$c319 = "ago";
  const peg$c320 = peg$literalExpectation("ago", false);
  const peg$c321 = "all time";
  const peg$c322 = peg$literalExpectation("all time", false);
  const peg$c323 = /^[0-9]/;
  const peg$c324 = peg$classExpectation([["0", "9"]], false, false);
  const peg$c325 = " to ";
  const peg$c326 = peg$literalExpectation(" to ", false);
  const peg$c327 = /^[01]/;
  const peg$c328 = peg$classExpectation(["0", "1"], false, false);
  const peg$c329 = /^[0-3]/;
  const peg$c330 = peg$classExpectation([["0", "3"]], false, false);
  const peg$c331 = function(from: any, to: any): any { return from.join("") + " to " + to.join(""); };
  const peg$c332 = "T";
  const peg$c333 = peg$literalExpectation("T", false);
  const peg$c334 = ":";
  const peg$c335 = peg$literalExpectation(":", false);
  const peg$c336 = peg$otherExpectation("value");
  const peg$c337 = peg$otherExpectation("numeric value");
  const peg$c338 = "null";
  const peg$c339 = peg$literalExpectation("null", false);
  const peg$c340 = function(id: any, otherIds: any): any { return [id].concat(otherIds || []); };
  const peg$c341 = /^[a-zA-Z]/;
  const peg$c342 = peg$classExpectation([["a", "z"], ["A", "Z"]], false, false);
  const peg$c343 = peg$otherExpectation("whitespace");
  const peg$c344 = /^[ \n\r]/;
  const peg$c345 = peg$classExpectation([" ", "\n", "\r"], false, false);
  const peg$c346 = peg$otherExpectation("percentage");
  const peg$c347 = "%";
  const peg$c348 = peg$literalExpectation("%", false);
  const peg$c349 = function(v: any): any { return v / 100.0; };
  const peg$c350 = peg$otherExpectation("integer");
  const peg$c351 = /^[0-9\-+]/;
  const peg$c352 = peg$classExpectation([["0", "9"], "-", "+"], false, false);
  const peg$c353 = function(digits: any): any { return parseInt(digits.join(""), 10); };
  const peg$c354 = peg$otherExpectation("real");
  const peg$c355 = ".";
  const peg$c356 = peg$literalExpectation(".", false);
  const peg$c357 = function(digits: any, frac: any): any { return parseFloat(digits.join("") + "." + frac.join("")); };
  const peg$c358 = peg$otherExpectation("string");
  const peg$c359 = "\"";
  const peg$c360 = peg$literalExpectation("\"", false);
  const peg$c361 = function(chars: any): any { return chars.join(""); };
  const peg$c362 = "\\";
  const peg$c363 = peg$literalExpectation("\\", false);
  const peg$c364 = peg$anyExpectation();
  const peg$c365 = function(char: any): any { return char; };
  const peg$c366 = function(seq: any): any { return seq; };
  const peg$c367 = peg$otherExpectation("escape sequence");
  const peg$c368 = "t";
  const peg$c369 = peg$literalExpectation("t", false);
  const peg$c370 = function(): any { return "\t"; };
  const peg$c371 = "n";
  const peg$c372 = peg$literalExpectation("n", false);
  const peg$c373 = function(): any { return "\n"; };
  const peg$c374 = "r";
  const peg$c375 = peg$literalExpectation("r", false);
  const peg$c376 = function(): any { return "\r"; };
  const peg$c377 = "u";
  const peg$c378 = peg$literalExpectation("u", false);
  const peg$c379 = /^[0-9a-fA-F]/;
  const peg$c380 = peg$classExpectation([["0", "9"], ["a", "f"], ["A", "F"]], false, false);
  const peg$c381 = function(digits: any): any {
          return String.fromCharCode(parseInt(digits.join(""), 16));
      };

  let peg$currPos = 0;
  let peg$savedPos = 0;
  const peg$posDetailsCache = [{ line: 1, column: 1 }];
  let peg$maxFailPos = 0;
  let peg$maxFailExpected: Expectation[] = [];
  let peg$silentFails = 0;

  let peg$result;

  if (options.startRule !== undefined) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text(): string {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location(): FileRange {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description: string, location1?: FileRange) {
    location1 = location1 !== undefined
      ? location1
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location1
    );
  }

  function error(message: string, location1?: FileRange) {
    location1 = location1 !== undefined
      ? location1
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildSimpleError(message, location1);
  }

  function peg$literalExpectation(text1: string, ignoreCase: boolean): LiteralExpectation {
    return { type: "literal", text: text1, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts: ClassParts, inverted: boolean, ignoreCase: boolean): ClassExpectation {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation(): AnyExpectation {
    return { type: "any" };
  }

  function peg$endExpectation(): EndExpectation {
    return { type: "end" };
  }

  function peg$otherExpectation(description: string): OtherExpectation {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos: number) {
    let details = peg$posDetailsCache[pos];
    let p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line: details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;

      return details;
    }
  }

  function peg$computeLocation(startPos: number, endPos: number): FileRange {
    const startPosDetails = peg$computePosDetails(startPos);
    const endPosDetails = peg$computePosDetails(endPos);

    return {
      source: peg$source,
      start: {
        offset: startPos,
        line: startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line: endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected1: Expectation) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected1);
  }

  function peg$buildSimpleError(message: string, location1: FileRange) {
    return new PeggySyntaxError(message, [], "", location1);
  }

  function peg$buildStructuredError(expected1: Expectation[], found: string | null, location1: FileRange) {
    return new PeggySyntaxError(
      PeggySyntaxError.buildMessage(expected1, found),
      expected1,
      found,
      location1
    );
  }

  function peg$parsestart(): any {
    let s0;

    s0 = peg$parseexpr();

    return s0;
  }

  function peg$parseexpr(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsefactor();
    if (s1 as any !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseexprRhs();
      while (s3 as any !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseexprRhs();
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c0(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsefactor();
    }

    return s0;
  }

  function peg$parseexprRhs(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseboolOp();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsefactor();
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c1(s2, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parselimit(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parserandTerm();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsepercentage();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c2(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parserandTerm();
      if (s1 as any !== peg$FAILED) {
        s2 = peg$parses();
        if (s2 as any !== peg$FAILED) {
          s3 = peg$parseinteger();
          if (s3 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c3(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsevalueTerm();
        if (s1 as any !== peg$FAILED) {
          s2 = peg$parses();
          if (s2 as any !== peg$FAILED) {
            s3 = peg$parsepercentage();
            if (s3 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c4(s1, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 as any === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parsevalueTerm();
          if (s1 as any !== peg$FAILED) {
            s2 = peg$parses();
            if (s2 as any !== peg$FAILED) {
              s3 = peg$parseinteger();
              if (s3 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c5(s1, s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    return s0;
  }

  function peg$parserandTerm(): any {
    let s0;

    if (input.substr(peg$currPos, 5) === peg$c6) {
      s0 = peg$c6;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c7); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c8) {
        s0 = peg$c8;
        peg$currPos += 6;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c9); }
      }
    }

    return s0;
  }

  function peg$parsevalueTerm(): any {
    let s0;

    if (input.substr(peg$currPos, 13) === peg$c10) {
      s0 = peg$c10;
      peg$currPos += 13;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c11); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 12) === peg$c12) {
        s0 = peg$c12;
        peg$currPos += 12;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c13); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 20) === peg$c14) {
          s0 = peg$c14;
          peg$currPos += 20;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c15); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 19) === peg$c16) {
            s0 = peg$c16;
            peg$currPos += 19;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c17); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 12) === peg$c18) {
              s0 = peg$c18;
              peg$currPos += 12;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c19); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 13) === peg$c20) {
                s0 = peg$c20;
                peg$currPos += 13;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c21); }
              }
              if (s0 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 23) === peg$c22) {
                  s0 = peg$c22;
                  peg$currPos += 23;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c23); }
                }
                if (s0 as any === peg$FAILED) {
                  if (input.substr(peg$currPos, 21) === peg$c24) {
                    s0 = peg$c24;
                    peg$currPos += 21;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c25); }
                  }
                  if (s0 as any === peg$FAILED) {
                    if (input.substr(peg$currPos, 25) === peg$c26) {
                      s0 = peg$c26;
                      peg$currPos += 25;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c27); }
                    }
                    if (s0 as any === peg$FAILED) {
                      if (input.substr(peg$currPos, 23) === peg$c28) {
                        s0 = peg$c28;
                        peg$currPos += 23;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c29); }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseboolOp(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$parseandOp();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parseorOp();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parseexceptOp();
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c30); }
    }

    return s0;
  }

  function peg$parseandOp(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c32) {
      s1 = peg$c32;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c33); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c34) {
        s1 = peg$c34;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c35); }
      }
      if (s1 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c36) {
          s1 = peg$c36;
          peg$currPos += 9;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c37); }
        }
        if (s1 as any === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 38) {
            s1 = peg$c38;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c39); }
          }
        }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c40();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c31); }
    }

    return s0;
  }

  function peg$parseorOp(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c42) {
      s1 = peg$c42;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c44) {
        s1 = peg$c44;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c45); }
      }
      if (s1 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c46) {
          s1 = peg$c46;
          peg$currPos += 5;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c47); }
        }
        if (s1 as any === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 124) {
            s1 = peg$c48;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c49); }
          }
        }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c50();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c41); }
    }

    return s0;
  }

  function peg$parseexceptOp(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c52) {
      s1 = peg$c52;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c53); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 45) {
        s1 = peg$c54;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c55); }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c56();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c51); }
    }

    return s0;
  }

  function peg$parsefactor(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsenotOp();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parselimitAtom();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c57(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$parselimitAtom();
    }

    return s0;
  }

  function peg$parsenotOp(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.substr(peg$currPos, 4) === peg$c59) {
      s0 = peg$c59;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c60); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 126) {
        s0 = peg$c61;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c62); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 33) {
          s0 = peg$c63;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c64); }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c58); }
    }

    return s0;
  }

  function peg$parselimitAtom(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseatom();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parselimit();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c65(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$parseatom();
    }

    return s0;
  }

  function peg$parseatom(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 40) {
      s1 = peg$c66;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c67); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseexpr();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parses();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 41) {
              s5 = peg$c68;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c69); }
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c70(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsehistoryPredicate();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parsebirthdayPredicate();
        if (s0 as any === peg$FAILED) {
          s0 = peg$parsepurchasePredicate();
          if (s0 as any === peg$FAILED) {
            s0 = peg$parsevisitPredicate();
            if (s0 as any === peg$FAILED) {
              s0 = peg$parsecontactablePredicate();
              if (s0 as any === peg$FAILED) {
                s0 = peg$parsetrackPredicate();
                if (s0 as any === peg$FAILED) {
                  s0 = peg$parsepartitionPredicate();
                  if (s0 as any === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$parsefield();
                    if (s1 as any !== peg$FAILED) {
                      s2 = peg$parses();
                      if (s2 as any === peg$FAILED) {
                        s2 = null;
                      }
                      if (s2 as any !== peg$FAILED) {
                        s3 = peg$parsefieldRangePredicateOp();
                        if (s3 as any !== peg$FAILED) {
                          s4 = peg$parsedateTerm();
                          if (s4 as any !== peg$FAILED) {
                            peg$savedPos = s0;
                            s1 = peg$c71(s1, s3, s4);
                            s0 = s1;
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                    if (s0 as any === peg$FAILED) {
                      s0 = peg$currPos;
                      s1 = peg$parsefield();
                      if (s1 as any !== peg$FAILED) {
                        s2 = peg$parses();
                        if (s2 as any === peg$FAILED) {
                          s2 = null;
                        }
                        if (s2 as any !== peg$FAILED) {
                          s3 = peg$parsefieldPredicateOp();
                          if (s3 as any !== peg$FAILED) {
                            s4 = peg$parses();
                            if (s4 as any === peg$FAILED) {
                              s4 = null;
                            }
                            if (s4 as any !== peg$FAILED) {
                              s5 = peg$parsevalue();
                              if (s5 as any !== peg$FAILED) {
                                peg$savedPos = s0;
                                s1 = peg$c72(s1, s3, s5);
                                s0 = s1;
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                      if (s0 as any === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parsefield();
                        if (s1 as any !== peg$FAILED) {
                          s2 = peg$parses();
                          if (s2 as any === peg$FAILED) {
                            s2 = null;
                          }
                          if (s2 as any !== peg$FAILED) {
                            s3 = peg$parsefieldPredicateOp();
                            if (s3 as any !== peg$FAILED) {
                              s4 = peg$parses();
                              if (s4 as any === peg$FAILED) {
                                s4 = null;
                              }
                              if (s4 as any !== peg$FAILED) {
                                s5 = peg$parseexplicitCustomField();
                                if (s5 as any !== peg$FAILED) {
                                  peg$savedPos = s0;
                                  s1 = peg$c72(s1, s3, s5);
                                  s0 = s1;
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsehistoryPredicate(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parselistsPredicateOp();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseintegerList();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          s5 = peg$parsecriteriaList();
          peg$silentFails--;
          if (s5 as any === peg$FAILED) {
            s4 = undefined;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 as any !== peg$FAILED) {
            s5 = peg$currPos;
            peg$silentFails++;
            s6 = peg$parsedateTerm();
            peg$silentFails--;
            if (s6 as any === peg$FAILED) {
              s5 = undefined;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 as any !== peg$FAILED) {
              s6 = peg$parses();
              if (s6 as any !== peg$FAILED) {
                s7 = peg$parsestring();
                if (s7 as any !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c73(s1, s3, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parselistsPredicateOp();
      if (s1 as any !== peg$FAILED) {
        s2 = peg$parses();
        if (s2 as any !== peg$FAILED) {
          s3 = peg$parseintegerList();
          if (s3 as any !== peg$FAILED) {
            s4 = peg$parsecriteriaList();
            if (s4 as any === peg$FAILED) {
              s4 = null;
            }
            if (s4 as any !== peg$FAILED) {
              s5 = peg$parsedateTerm();
              if (s5 as any === peg$FAILED) {
                s5 = null;
              }
              if (s5 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c74(s1, s3, s4, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 24) === peg$c75) {
          s1 = peg$c75;
          peg$currPos += 24;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c76); }
        }
        if (s1 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 24) === peg$c75) {
            s1 = peg$c75;
            peg$currPos += 24;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c76); }
          }
          if (s1 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 22) === peg$c77) {
              s1 = peg$c77;
              peg$currPos += 22;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c78); }
            }
            if (s1 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 21) === peg$c79) {
                s1 = peg$c79;
                peg$currPos += 21;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c80); }
              }
            }
          }
        }
        if (s1 as any !== peg$FAILED) {
          s2 = peg$parses();
          if (s2 as any !== peg$FAILED) {
            s3 = peg$parseintegerList();
            if (s3 as any !== peg$FAILED) {
              s4 = peg$parses();
              if (s4 as any !== peg$FAILED) {
                s5 = peg$parsestringList();
                if (s5 as any !== peg$FAILED) {
                  s6 = peg$parsedateTerm();
                  if (s6 as any === peg$FAILED) {
                    s6 = null;
                  }
                  if (s6 as any !== peg$FAILED) {
                    s7 = peg$parsecountAggregate();
                    if (s7 as any === peg$FAILED) {
                      s7 = null;
                    }
                    if (s7 as any !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c81(s3, s5, s6, s7);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 as any === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 13) === peg$c82) {
            s1 = peg$c82;
            peg$currPos += 13;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c83); }
          }
          if (s1 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 12) === peg$c84) {
              s1 = peg$c84;
              peg$currPos += 12;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c85); }
            }
          }
          if (s1 as any !== peg$FAILED) {
            s2 = peg$parses();
            if (s2 as any !== peg$FAILED) {
              s3 = peg$parsestringList();
              if (s3 as any !== peg$FAILED) {
                s4 = peg$parseactivitiesTerm();
                if (s4 as any === peg$FAILED) {
                  s4 = null;
                }
                if (s4 as any !== peg$FAILED) {
                  s5 = peg$parsedateTerm();
                  if (s5 as any === peg$FAILED) {
                    s5 = null;
                  }
                  if (s5 as any !== peg$FAILED) {
                    s6 = peg$parsecountAggregate();
                    if (s6 as any === peg$FAILED) {
                      s6 = null;
                    }
                    if (s6 as any !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c86(s3, s4, s5, s6);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 as any === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 24) === peg$c87) {
              s1 = peg$c87;
              peg$currPos += 24;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c88); }
            }
            if (s1 as any !== peg$FAILED) {
              s2 = peg$parses();
              if (s2 as any !== peg$FAILED) {
                s3 = peg$parsestring();
                if (s3 as any !== peg$FAILED) {
                  s4 = peg$parseactivitiesTerm();
                  if (s4 as any === peg$FAILED) {
                    s4 = null;
                  }
                  if (s4 as any !== peg$FAILED) {
                    s5 = peg$parsedateTerm();
                    if (s5 as any === peg$FAILED) {
                      s5 = null;
                    }
                    if (s5 as any !== peg$FAILED) {
                      s6 = peg$parsecountAggregate();
                      if (s6 as any === peg$FAILED) {
                        s6 = null;
                      }
                      if (s6 as any !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c86(s3, s4, s5, s6);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 as any === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 11) === peg$c89) {
                s1 = peg$c89;
                peg$currPos += 11;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c90); }
              }
              if (s1 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 10) === peg$c91) {
                  s1 = peg$c91;
                  peg$currPos += 10;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c92); }
                }
              }
              if (s1 as any !== peg$FAILED) {
                s2 = peg$parses();
                if (s2 as any !== peg$FAILED) {
                  s3 = peg$parseintegerList();
                  if (s3 as any !== peg$FAILED) {
                    s4 = peg$parsewithTerm();
                    if (s4 as any === peg$FAILED) {
                      s4 = null;
                    }
                    if (s4 as any !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c93(s3, s4);
                      s0 = s1;
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 as any === peg$FAILED) {
                s0 = peg$parseresourcePredicateOp();
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parselistsPredicateOp(): any {
    let s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c94) {
      s1 = peg$c94;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c95); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 15) === peg$c96) {
        s1 = peg$c96;
        peg$currPos += 15;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c97); }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c98();
    }
    s0 = s1;
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 18) === peg$c99) {
        s1 = peg$c99;
        peg$currPos += 18;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c100); }
      }
      if (s1 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 17) === peg$c101) {
          s1 = peg$c101;
          peg$currPos += 17;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c102); }
        }
      }
      if (s1 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c103();
      }
      s0 = s1;
    }

    return s0;
  }

  function peg$parseresourcePredicateOp(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseeventName();
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 32) {
        s2 = peg$c104;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c105); }
      }
      if (s2 as any !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c106) {
          s3 = peg$c106;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c107); }
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsedateTerm();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsecountAggregate();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c108(s1, s4, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseeventName();
      if (s1 as any !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 32) {
          s2 = peg$c104;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c105); }
        }
        if (s2 as any !== peg$FAILED) {
          s3 = peg$parseresourceType();
          if (s3 as any !== peg$FAILED) {
            s4 = peg$parses();
            if (s4 as any !== peg$FAILED) {
              s5 = peg$parseintegerList();
              if (s5 as any !== peg$FAILED) {
                s6 = peg$parsedateTerm();
                if (s6 as any === peg$FAILED) {
                  s6 = null;
                }
                if (s6 as any !== peg$FAILED) {
                  s7 = peg$parsecountAggregate();
                  if (s7 as any === peg$FAILED) {
                    s7 = null;
                  }
                  if (s7 as any !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c109(s1, s3, s5, s6, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseeventName();
        if (s1 as any !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 32) {
            s2 = peg$c104;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c105); }
          }
          if (s2 as any !== peg$FAILED) {
            s3 = peg$parseactivityType();
            if (s3 as any !== peg$FAILED) {
              s4 = peg$parsedateTerm();
              if (s4 as any === peg$FAILED) {
                s4 = null;
              }
              if (s4 as any !== peg$FAILED) {
                s5 = peg$parsecountAggregate();
                if (s5 as any === peg$FAILED) {
                  s5 = null;
                }
                if (s5 as any !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c110(s1, s3, s4, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parsefieldPredicateOp(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 61) {
      s0 = peg$c112;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c113); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c114) {
        s0 = peg$c114;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c115); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c116) {
          s0 = peg$c116;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c117); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 62) {
            s0 = peg$c118;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c119); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c120) {
              s0 = peg$c120;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c121); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 60) {
                s0 = peg$c122;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c123); }
              }
              if (s0 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c124) {
                  s0 = peg$c124;
                  peg$currPos += 4;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c125); }
                }
                if (s0 as any === peg$FAILED) {
                  if (input.substr(peg$currPos, 8) === peg$c126) {
                    s0 = peg$c126;
                    peg$currPos += 8;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c127); }
                  }
                  if (s0 as any === peg$FAILED) {
                    if (input.substr(peg$currPos, 5) === peg$c128) {
                      s0 = peg$c128;
                      peg$currPos += 5;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c129); }
                    }
                    if (s0 as any === peg$FAILED) {
                      if (input.substr(peg$currPos, 9) === peg$c130) {
                        s0 = peg$c130;
                        peg$currPos += 9;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c131); }
                      }
                      if (s0 as any === peg$FAILED) {
                        if (input.substr(peg$currPos, 6) === peg$c132) {
                          s0 = peg$c132;
                          peg$currPos += 6;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c133); }
                        }
                        if (s0 as any === peg$FAILED) {
                          if (input.substr(peg$currPos, 2) === peg$c134) {
                            s0 = peg$c134;
                            peg$currPos += 2;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c135); }
                          }
                          if (s0 as any === peg$FAILED) {
                            if (input.substr(peg$currPos, 6) === peg$c136) {
                              s0 = peg$c136;
                              peg$currPos += 6;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c137); }
                            }
                            if (s0 as any === peg$FAILED) {
                              if (input.substr(peg$currPos, 10) === peg$c138) {
                                s0 = peg$c138;
                                peg$currPos += 10;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c139); }
                              }
                              if (s0 as any === peg$FAILED) {
                                if (input.substr(peg$currPos, 2) === peg$c140) {
                                  s0 = peg$c140;
                                  peg$currPos += 2;
                                } else {
                                  s0 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c141); }
                                }
                                if (s0 as any === peg$FAILED) {
                                  if (input.charCodeAt(peg$currPos) === 126) {
                                    s0 = peg$c61;
                                    peg$currPos++;
                                  } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c62); }
                                  }
                                  if (s0 as any === peg$FAILED) {
                                    if (input.substr(peg$currPos, 3) === peg$c142) {
                                      s0 = peg$c142;
                                      peg$currPos += 3;
                                    } else {
                                      s0 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c143); }
                                    }
                                    if (s0 as any === peg$FAILED) {
                                      if (input.substr(peg$currPos, 2) === peg$c144) {
                                        s0 = peg$c144;
                                        peg$currPos += 2;
                                      } else {
                                        s0 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c145); }
                                      }
                                      if (s0 as any === peg$FAILED) {
                                        if (input.substr(peg$currPos, 2) === peg$c146) {
                                          s0 = peg$c146;
                                          peg$currPos += 2;
                                        } else {
                                          s0 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c147); }
                                        }
                                        if (s0 as any === peg$FAILED) {
                                          if (input.substr(peg$currPos, 3) === peg$c148) {
                                            s0 = peg$c148;
                                            peg$currPos += 3;
                                          } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c149); }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c111); }
    }

    return s0;
  }

  function peg$parsefieldRangePredicateOp(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.substr(peg$currPos, 6) === peg$c132) {
      s0 = peg$c132;
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c133); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c134) {
        s0 = peg$c134;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c135); }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c150); }
    }

    return s0;
  }

  function peg$parseactivitiesTerm(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      if (input.substr(peg$currPos, 8) === peg$c151) {
        s2 = peg$c151;
        peg$currPos += 8;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c152); }
      }
      if (s2 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 10) === peg$c153) {
          s2 = peg$c153;
          peg$currPos += 10;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c154); }
        }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseintegerList();
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c155(s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseeventName(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.substr(peg$currPos, 4) === peg$c157) {
      s0 = peg$c157;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c158); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 7) === peg$c159) {
        s0 = peg$c159;
        peg$currPos += 7;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c160); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c161) {
          s0 = peg$c161;
          peg$currPos += 6;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c162); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 10) === peg$c163) {
            s0 = peg$c163;
            peg$currPos += 10;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c164); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 12) === peg$c165) {
              s0 = peg$c165;
              peg$currPos += 12;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c166); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 7) === peg$c167) {
                s0 = peg$c167;
                peg$currPos += 7;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c168); }
              }
              if (s0 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 9) === peg$c169) {
                  s0 = peg$c169;
                  peg$currPos += 9;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c170); }
                }
                if (s0 as any === peg$FAILED) {
                  if (input.substr(peg$currPos, 7) === peg$c171) {
                    s0 = peg$c171;
                    peg$currPos += 7;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c172); }
                  }
                  if (s0 as any === peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c173) {
                      s0 = peg$c173;
                      peg$currPos += 6;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c174); }
                    }
                    if (s0 as any === peg$FAILED) {
                      if (input.substr(peg$currPos, 7) === peg$c175) {
                        s0 = peg$c175;
                        peg$currPos += 7;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c176); }
                      }
                      if (s0 as any === peg$FAILED) {
                        if (input.substr(peg$currPos, 8) === peg$c177) {
                          s0 = peg$c177;
                          peg$currPos += 8;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c178); }
                        }
                        if (s0 as any === peg$FAILED) {
                          if (input.substr(peg$currPos, 9) === peg$c179) {
                            s0 = peg$c179;
                            peg$currPos += 9;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c180); }
                          }
                          if (s0 as any === peg$FAILED) {
                            if (input.substr(peg$currPos, 7) === peg$c181) {
                              s0 = peg$c181;
                              peg$currPos += 7;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c182); }
                            }
                            if (s0 as any === peg$FAILED) {
                              if (input.substr(peg$currPos, 5) === peg$c183) {
                                s0 = peg$c183;
                                peg$currPos += 5;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c184); }
                              }
                              if (s0 as any === peg$FAILED) {
                                if (input.substr(peg$currPos, 9) === peg$c185) {
                                  s0 = peg$c185;
                                  peg$currPos += 9;
                                } else {
                                  s0 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c186); }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c156); }
    }

    return s0;
  }

  function peg$parseresourceType(): any {
    let s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c153) {
      s1 = peg$c153;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c154); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 8) === peg$c151) {
        s1 = peg$c151;
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c152); }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c187();
    }
    s0 = s1;
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 9) === peg$c188) {
        s1 = peg$c188;
        peg$currPos += 9;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c189); }
      }
      if (s1 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c190) {
          s1 = peg$c190;
          peg$currPos += 8;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c191); }
        }
      }
      if (s1 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c192();
      }
      s0 = s1;
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 9) === peg$c193) {
          s1 = peg$c193;
          peg$currPos += 9;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c194); }
        }
        if (s1 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 8) === peg$c195) {
            s1 = peg$c195;
            peg$currPos += 8;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c196); }
          }
        }
        if (s1 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c197();
        }
        s0 = s1;
        if (s0 as any === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 14) === peg$c198) {
            s1 = peg$c198;
            peg$currPos += 14;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c199); }
          }
          if (s1 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 13) === peg$c200) {
              s1 = peg$c200;
              peg$currPos += 13;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c201); }
            }
          }
          if (s1 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c202();
          }
          s0 = s1;
        }
      }
    }

    return s0;
  }

  function peg$parseactivityType(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.substr(peg$currPos, 5) === peg$c204) {
      s0 = peg$c204;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c205); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c206) {
        s0 = peg$c206;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c207); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c208) {
          s0 = peg$c208;
          peg$currPos += 8;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c209); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c210) {
            s0 = peg$c210;
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c211); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 9) === peg$c212) {
              s0 = peg$c212;
              peg$currPos += 9;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c213); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c214) {
                s0 = peg$c214;
                peg$currPos += 4;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c215); }
              }
              if (s0 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 16) === peg$c216) {
                  s0 = peg$c216;
                  peg$currPos += 16;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c217); }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c203); }
    }

    return s0;
  }

  function peg$parsecountAggregate(): any {
    let s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c219) {
        s2 = peg$c219;
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c220); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseaggregateOp();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseinteger();
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c221(s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c218); }
    }

    return s0;
  }

  function peg$parseaggregateOp(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 61) {
      s0 = peg$c112;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c113); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c114) {
        s0 = peg$c114;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c115); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c116) {
          s0 = peg$c116;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c117); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 62) {
            s0 = peg$c118;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c119); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c120) {
              s0 = peg$c120;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c121); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 60) {
                s0 = peg$c122;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c123); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedateTermOrString(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsedateRangeOrString();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c222(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedateTerm(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsedateRange();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c222(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsewithTerm(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c223) {
        s2 = peg$c223;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c224); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsestring();
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c225(s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsebirthdayPredicate(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8) === peg$c226) {
      s1 = peg$c226;
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c227); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsedateTerm();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c228(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecriteriaList(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsecriterionTerm();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsesubsequentCriterionTerm();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c229(s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecriterionTerm(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsefield();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsefieldRangePredicateOp();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsedateTerm();
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c230(s1, s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsefield();
      if (s1 as any !== peg$FAILED) {
        s2 = peg$parses();
        if (s2 as any === peg$FAILED) {
          s2 = null;
        }
        if (s2 as any !== peg$FAILED) {
          s3 = peg$parsefieldPredicateOp();
          if (s3 as any !== peg$FAILED) {
            s4 = peg$parses();
            if (s4 as any === peg$FAILED) {
              s4 = null;
            }
            if (s4 as any !== peg$FAILED) {
              s5 = peg$parsevalue();
              if (s5 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c231(s1, s3, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsefield();
        if (s1 as any !== peg$FAILED) {
          s2 = peg$parses();
          if (s2 as any === peg$FAILED) {
            s2 = null;
          }
          if (s2 as any !== peg$FAILED) {
            s3 = peg$parsefieldPredicateOp();
            if (s3 as any !== peg$FAILED) {
              s4 = peg$parses();
              if (s4 as any === peg$FAILED) {
                s4 = null;
              }
              if (s4 as any !== peg$FAILED) {
                s5 = peg$parseexplicitCustomField();
                if (s5 as any !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c231(s1, s3, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parsesubsequentCriterionTerm(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 44) {
        s2 = peg$c232;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c233); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsecriterionTerm();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsesubsequentCriterionTerm();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c229(s4, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsepurchasePredicate(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9) === peg$c234) {
      s1 = peg$c234;
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c235); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsecriteriaList();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsedateTerm();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsecountAggregate();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c236(s2, s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsevisitPredicate(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c237) {
      s1 = peg$c237;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c238); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsecriteriaList();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsedateTerm();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsecountAggregate();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c239(s2, s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsetrackPredicate(): any {
    let s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c240) {
      s1 = peg$c240;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c241); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsetrackEventType();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsecriteriaList();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsedateTerm();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              s6 = peg$parsecountAggregate();
              if (s6 as any === peg$FAILED) {
                s6 = null;
              }
              if (s6 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c242(s3, s4, s5, s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsetrackEventType(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.substr(peg$currPos, 4) === peg$c244) {
      s0 = peg$c244;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c245); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c246) {
        s0 = peg$c246;
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c247); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 7) === peg$c248) {
          s0 = peg$c248;
          peg$currPos += 7;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c249); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c250) {
            s0 = peg$c250;
            peg$currPos += 6;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c251); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 6) === peg$c252) {
              s0 = peg$c252;
              peg$currPos += 6;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c253); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 5) === peg$c254) {
                s0 = peg$c254;
                peg$currPos += 5;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c255); }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c243); }
    }

    return s0;
  }

  function peg$parsepartitionPredicate(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9) === peg$c256) {
      s1 = peg$c256;
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c257); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsecriteriaList();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c258(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecontactablePredicate(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c259) {
      s1 = peg$c259;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c260); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c261) {
          s3 = peg$c261;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c262); }
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parses();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsechannel();
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c263(s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsechannel(): any {
    let s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c265.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c266); }
    }
    if (s2 as any !== peg$FAILED) {
      while (s2 as any !== peg$FAILED) {
        s1.push(s2);
        if (peg$c265.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c266); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c267(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c264); }
    }

    return s0;
  }

  function peg$parsefieldVal(): any {
    let s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$currPos;
    if (peg$c265.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c266); }
    }
    if (s2 as any !== peg$FAILED) {
      s3 = [];
      if (peg$c269.test(input.charAt(peg$currPos))) {
        s4 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c270); }
      }
      while (s4 as any !== peg$FAILED) {
        s3.push(s4);
        if (peg$c269.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c270); }
        }
      }
      if (s3 as any !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c271(s1);
    }
    s0 = s1;
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 39) {
        s1 = peg$c272;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c273); }
      }
      if (s1 as any !== peg$FAILED) {
        s2 = [];
        if (peg$c274.test(input.charAt(peg$currPos))) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c275); }
        }
        if (s3 as any !== peg$FAILED) {
          while (s3 as any !== peg$FAILED) {
            s2.push(s3);
            if (peg$c274.test(input.charAt(peg$currPos))) {
              s3 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c275); }
            }
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 as any !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 39) {
            s3 = peg$c272;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c273); }
          }
          if (s3 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c267(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c268); }
    }

    return s0;
  }

  function peg$parseexplicitCustomField(): any {
    let s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c277) {
      s1 = peg$c277;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c278); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsefieldVal();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c279(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c276); }
    }

    return s0;
  }

  function peg$parsefield(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$parseexplicitCustomField();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsefieldVal();
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c280); }
    }

    return s0;
  }

  function peg$parsedateRangeOrString(): any {
    let s0;

    s0 = peg$parsedateRange();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsestring();
    }

    return s0;
  }

  function peg$parsedateDirection(): any {
    let s0;

    if (input.substr(peg$currPos, 4) === peg$c281) {
      s0 = peg$c281;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c282); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c283) {
        s0 = peg$c283;
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c284); }
      }
    }

    return s0;
  }

  function peg$parsedateUnitSingular(): any {
    let s0;

    if (input.substr(peg$currPos, 4) === peg$c285) {
      s0 = peg$c285;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c286); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c287) {
        s0 = peg$c287;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c288); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c289) {
          s0 = peg$c289;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c290); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c291) {
            s0 = peg$c291;
            peg$currPos += 5;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c292); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c293) {
              s0 = peg$c293;
              peg$currPos += 4;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c294); }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedateUnitPlural(): any {
    let s0;

    if (input.substr(peg$currPos, 5) === peg$c295) {
      s0 = peg$c295;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c296); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c297) {
        s0 = peg$c297;
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c298); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c299) {
          s0 = peg$c299;
          peg$currPos += 5;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c300); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c301) {
            s0 = peg$c301;
            peg$currPos += 6;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c302); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 5) === peg$c303) {
              s0 = peg$c303;
              peg$currPos += 5;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c304); }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedateRange(): any {
    let s0, s1, s2, s3, s4, s5, s6;

    peg$silentFails++;
    if (input.substr(peg$currPos, 9) === peg$c306) {
      s0 = peg$c306;
      peg$currPos += 9;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c307); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c308) {
        s0 = peg$c308;
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c309); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c310) {
          s0 = peg$c310;
          peg$currPos += 8;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c311); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c312) {
            s0 = peg$c312;
            peg$currPos += 9;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c313); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 10) === peg$c314) {
              s0 = peg$c314;
              peg$currPos += 10;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c315); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 9) === peg$c316) {
                s0 = peg$c316;
                peg$currPos += 9;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c317); }
              }
              if (s0 as any === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$currPos;
                s2 = peg$parsedateDirection();
                if (s2 as any !== peg$FAILED) {
                  s3 = peg$parses();
                  if (s3 as any !== peg$FAILED) {
                    s4 = peg$parsedateUnitSingular();
                    if (s4 as any !== peg$FAILED) {
                      s2 = [s2, s3, s4];
                      s1 = s2;
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
                if (s1 as any !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c318(s1);
                }
                s0 = s1;
                if (s0 as any === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$currPos;
                  s2 = peg$parsedateDirection();
                  if (s2 as any !== peg$FAILED) {
                    s3 = peg$parses();
                    if (s3 as any !== peg$FAILED) {
                      s4 = peg$parseinteger();
                      if (s4 as any !== peg$FAILED) {
                        s5 = peg$parses();
                        if (s5 as any !== peg$FAILED) {
                          s6 = peg$parsedateUnitPlural();
                          if (s6 as any !== peg$FAILED) {
                            s2 = [s2, s3, s4, s5, s6];
                            s1 = s2;
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                  if (s1 as any !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c318(s1);
                  }
                  s0 = s1;
                  if (s0 as any === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$currPos;
                    s2 = peg$parseinteger();
                    if (s2 as any !== peg$FAILED) {
                      s3 = peg$parses();
                      if (s3 as any !== peg$FAILED) {
                        s4 = peg$parsedateUnitPlural();
                        if (s4 as any === peg$FAILED) {
                          s4 = peg$parsedateUnitSingular();
                        }
                        if (s4 as any !== peg$FAILED) {
                          s5 = peg$parses();
                          if (s5 as any !== peg$FAILED) {
                            if (input.substr(peg$currPos, 3) === peg$c319) {
                              s6 = peg$c319;
                              peg$currPos += 3;
                            } else {
                              s6 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c320); }
                            }
                            if (s6 as any !== peg$FAILED) {
                              s2 = [s2, s3, s4, s5, s6];
                              s1 = s2;
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                    if (s1 as any !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c318(s1);
                    }
                    s0 = s1;
                    if (s0 as any === peg$FAILED) {
                      if (input.substr(peg$currPos, 8) === peg$c321) {
                        s0 = peg$c321;
                        peg$currPos += 8;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c322); }
                      }
                      if (s0 as any === peg$FAILED) {
                        s0 = peg$parsedateFromTo();
                        if (s0 as any === peg$FAILED) {
                          s0 = peg$parsedateTimeFromTo();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c305); }
    }

    return s0;
  }

  function peg$parsedateFromTo(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (peg$c323.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c324); }
    }
    if (s2 as any !== peg$FAILED) {
      if (peg$c323.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c324); }
      }
      if (s3 as any !== peg$FAILED) {
        if (peg$c323.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c324); }
        }
        if (s4 as any !== peg$FAILED) {
          if (peg$c323.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c324); }
          }
          if (s5 as any !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 45) {
              s6 = peg$c54;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c55); }
            }
            if (s6 as any !== peg$FAILED) {
              if (peg$c323.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c324); }
              }
              if (s7 as any !== peg$FAILED) {
                if (peg$c323.test(input.charAt(peg$currPos))) {
                  s8 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c324); }
                }
                if (s8 as any !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 45) {
                    s9 = peg$c54;
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c55); }
                  }
                  if (s9 as any !== peg$FAILED) {
                    if (peg$c323.test(input.charAt(peg$currPos))) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c324); }
                    }
                    if (s10 as any !== peg$FAILED) {
                      if (peg$c323.test(input.charAt(peg$currPos))) {
                        s11 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c324); }
                      }
                      if (s11 as any !== peg$FAILED) {
                        s2 = [s2, s3, s4, s5, s6, s7, s8, s9, s10, s11];
                        s1 = s2;
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c325) {
        s2 = peg$c325;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c326); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        if (peg$c323.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c324); }
        }
        if (s4 as any !== peg$FAILED) {
          if (peg$c323.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c324); }
          }
          if (s5 as any !== peg$FAILED) {
            if (peg$c323.test(input.charAt(peg$currPos))) {
              s6 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c324); }
            }
            if (s6 as any !== peg$FAILED) {
              if (peg$c323.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c324); }
              }
              if (s7 as any !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 45) {
                  s8 = peg$c54;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c55); }
                }
                if (s8 as any !== peg$FAILED) {
                  if (peg$c327.test(input.charAt(peg$currPos))) {
                    s9 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c328); }
                  }
                  if (s9 as any !== peg$FAILED) {
                    if (peg$c323.test(input.charAt(peg$currPos))) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c324); }
                    }
                    if (s10 as any !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 45) {
                        s11 = peg$c54;
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c55); }
                      }
                      if (s11 as any !== peg$FAILED) {
                        if (peg$c329.test(input.charAt(peg$currPos))) {
                          s12 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c330); }
                        }
                        if (s12 as any !== peg$FAILED) {
                          if (peg$c323.test(input.charAt(peg$currPos))) {
                            s13 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c324); }
                          }
                          if (s13 as any !== peg$FAILED) {
                            s4 = [s4, s5, s6, s7, s8, s9, s10, s11, s12, s13];
                            s3 = s4;
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c331(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedateTimeFromTo(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22;

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (peg$c323.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c324); }
    }
    if (s2 as any !== peg$FAILED) {
      if (peg$c323.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c324); }
      }
      if (s3 as any !== peg$FAILED) {
        if (peg$c323.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c324); }
        }
        if (s4 as any !== peg$FAILED) {
          if (peg$c323.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c324); }
          }
          if (s5 as any !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 45) {
              s6 = peg$c54;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c55); }
            }
            if (s6 as any !== peg$FAILED) {
              if (peg$c323.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c324); }
              }
              if (s7 as any !== peg$FAILED) {
                if (peg$c323.test(input.charAt(peg$currPos))) {
                  s8 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c324); }
                }
                if (s8 as any !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 45) {
                    s9 = peg$c54;
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c55); }
                  }
                  if (s9 as any !== peg$FAILED) {
                    if (peg$c323.test(input.charAt(peg$currPos))) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c324); }
                    }
                    if (s10 as any !== peg$FAILED) {
                      if (peg$c323.test(input.charAt(peg$currPos))) {
                        s11 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c324); }
                      }
                      if (s11 as any !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 84) {
                          s12 = peg$c332;
                          peg$currPos++;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c333); }
                        }
                        if (s12 as any !== peg$FAILED) {
                          if (peg$c323.test(input.charAt(peg$currPos))) {
                            s13 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c324); }
                          }
                          if (s13 as any !== peg$FAILED) {
                            if (peg$c323.test(input.charAt(peg$currPos))) {
                              s14 = input.charAt(peg$currPos);
                              peg$currPos++;
                            } else {
                              s14 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c324); }
                            }
                            if (s14 as any !== peg$FAILED) {
                              if (input.charCodeAt(peg$currPos) === 58) {
                                s15 = peg$c334;
                                peg$currPos++;
                              } else {
                                s15 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c335); }
                              }
                              if (s15 as any !== peg$FAILED) {
                                if (peg$c323.test(input.charAt(peg$currPos))) {
                                  s16 = input.charAt(peg$currPos);
                                  peg$currPos++;
                                } else {
                                  s16 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                }
                                if (s16 as any !== peg$FAILED) {
                                  if (peg$c323.test(input.charAt(peg$currPos))) {
                                    s17 = input.charAt(peg$currPos);
                                    peg$currPos++;
                                  } else {
                                    s17 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                  }
                                  if (s17 as any !== peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 58) {
                                      s18 = peg$c334;
                                      peg$currPos++;
                                    } else {
                                      s18 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c335); }
                                    }
                                    if (s18 as any !== peg$FAILED) {
                                      if (peg$c323.test(input.charAt(peg$currPos))) {
                                        s19 = input.charAt(peg$currPos);
                                        peg$currPos++;
                                      } else {
                                        s19 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                      }
                                      if (s19 as any !== peg$FAILED) {
                                        if (peg$c323.test(input.charAt(peg$currPos))) {
                                          s20 = input.charAt(peg$currPos);
                                          peg$currPos++;
                                        } else {
                                          s20 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                        }
                                        if (s20 as any !== peg$FAILED) {
                                          s2 = [s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20];
                                          s1 = s2;
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c325) {
        s2 = peg$c325;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c326); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        if (peg$c323.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c324); }
        }
        if (s4 as any !== peg$FAILED) {
          if (peg$c323.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c324); }
          }
          if (s5 as any !== peg$FAILED) {
            if (peg$c323.test(input.charAt(peg$currPos))) {
              s6 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c324); }
            }
            if (s6 as any !== peg$FAILED) {
              if (peg$c323.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c324); }
              }
              if (s7 as any !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 45) {
                  s8 = peg$c54;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c55); }
                }
                if (s8 as any !== peg$FAILED) {
                  if (peg$c327.test(input.charAt(peg$currPos))) {
                    s9 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c328); }
                  }
                  if (s9 as any !== peg$FAILED) {
                    if (peg$c323.test(input.charAt(peg$currPos))) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c324); }
                    }
                    if (s10 as any !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 45) {
                        s11 = peg$c54;
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c55); }
                      }
                      if (s11 as any !== peg$FAILED) {
                        if (peg$c329.test(input.charAt(peg$currPos))) {
                          s12 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c330); }
                        }
                        if (s12 as any !== peg$FAILED) {
                          if (peg$c323.test(input.charAt(peg$currPos))) {
                            s13 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c324); }
                          }
                          if (s13 as any !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 84) {
                              s14 = peg$c332;
                              peg$currPos++;
                            } else {
                              s14 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c333); }
                            }
                            if (s14 as any !== peg$FAILED) {
                              if (peg$c323.test(input.charAt(peg$currPos))) {
                                s15 = input.charAt(peg$currPos);
                                peg$currPos++;
                              } else {
                                s15 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c324); }
                              }
                              if (s15 as any !== peg$FAILED) {
                                if (peg$c323.test(input.charAt(peg$currPos))) {
                                  s16 = input.charAt(peg$currPos);
                                  peg$currPos++;
                                } else {
                                  s16 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                }
                                if (s16 as any !== peg$FAILED) {
                                  if (input.charCodeAt(peg$currPos) === 58) {
                                    s17 = peg$c334;
                                    peg$currPos++;
                                  } else {
                                    s17 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c335); }
                                  }
                                  if (s17 as any !== peg$FAILED) {
                                    if (peg$c323.test(input.charAt(peg$currPos))) {
                                      s18 = input.charAt(peg$currPos);
                                      peg$currPos++;
                                    } else {
                                      s18 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                    }
                                    if (s18 as any !== peg$FAILED) {
                                      if (peg$c323.test(input.charAt(peg$currPos))) {
                                        s19 = input.charAt(peg$currPos);
                                        peg$currPos++;
                                      } else {
                                        s19 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                      }
                                      if (s19 as any !== peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 58) {
                                          s20 = peg$c334;
                                          peg$currPos++;
                                        } else {
                                          s20 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c335); }
                                        }
                                        if (s20 as any !== peg$FAILED) {
                                          if (peg$c323.test(input.charAt(peg$currPos))) {
                                            s21 = input.charAt(peg$currPos);
                                            peg$currPos++;
                                          } else {
                                            s21 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                          }
                                          if (s21 as any !== peg$FAILED) {
                                            if (peg$c323.test(input.charAt(peg$currPos))) {
                                              s22 = input.charAt(peg$currPos);
                                              peg$currPos++;
                                            } else {
                                              s22 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c324); }
                                            }
                                            if (s22 as any !== peg$FAILED) {
                                              s4 = [s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22];
                                              s3 = s4;
                                            } else {
                                              peg$currPos = s3;
                                              s3 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s3;
                                            s3 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s3;
                                          s3 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s3;
                                        s3 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s3;
                                      s3 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s3;
                                    s3 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s3;
                                  s3 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s3;
                                s3 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s3;
                              s3 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c331(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsevalue(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$parsepercentage();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsereal();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parseinteger();
        if (s0 as any === peg$FAILED) {
          s0 = peg$parsenull();
          if (s0 as any === peg$FAILED) {
            s0 = peg$parsestring();
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c336); }
    }

    return s0;
  }

  function peg$parsenumeric(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$parsepercentage();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsereal();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parseinteger();
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c337); }
    }

    return s0;
  }

  function peg$parsenull(): any {
    let s0;

    if (input.substr(peg$currPos, 4) === peg$c338) {
      s0 = peg$c338;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c339); }
    }

    return s0;
  }

  function peg$parseintegerList(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseinteger();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsesubsequentInteger();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c340(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsesubsequentInteger(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 44) {
        s2 = peg$c232;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c233); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseinteger();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsesubsequentInteger();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c340(s4, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsestringList(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parsestring();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsesubsequentString();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c340(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsesubsequentString(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 44) {
        s2 = peg$c232;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c233); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsestring();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsesubsequentString();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c340(s4, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsealphas(): any {
    let s0, s1;

    s0 = [];
    if (peg$c341.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c342); }
    }
    if (s1 as any !== peg$FAILED) {
      while (s1 as any !== peg$FAILED) {
        s0.push(s1);
        if (peg$c341.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c342); }
        }
      }
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parses(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = [];
    if (peg$c344.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c345); }
    }
    if (s1 as any !== peg$FAILED) {
      while (s1 as any !== peg$FAILED) {
        s0.push(s1);
        if (peg$c344.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c345); }
        }
      }
    } else {
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c343); }
    }

    return s0;
  }

  function peg$parsepercentage(): any {
    let s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseinteger();
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 37) {
        s2 = peg$c347;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c348); }
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c349(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c346); }
    }

    return s0;
  }

  function peg$parseinteger(): any {
    let s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c351.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c352); }
    }
    if (s2 as any !== peg$FAILED) {
      while (s2 as any !== peg$FAILED) {
        s1.push(s2);
        if (peg$c351.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c352); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c353(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c350); }
    }

    return s0;
  }

  function peg$parsereal(): any {
    let s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c351.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c352); }
    }
    if (s2 as any !== peg$FAILED) {
      while (s2 as any !== peg$FAILED) {
        s1.push(s2);
        if (peg$c351.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c352); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 46) {
        s2 = peg$c355;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c356); }
      }
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = [];
        if (peg$c323.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c324); }
        }
        while (s4 as any !== peg$FAILED) {
          s3.push(s4);
          if (peg$c323.test(input.charAt(peg$currPos))) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c324); }
          }
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c357(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c354); }
    }

    return s0;
  }

  function peg$parsestring(): any {
    let s0, s1, s2, s3;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 34) {
      s1 = peg$c359;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c360); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = [];
      s3 = peg$parsedoubleQuoteStringChar();
      while (s3 as any !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parsedoubleQuoteStringChar();
      }
      if (s2 as any !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 34) {
          s3 = peg$c359;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c360); }
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c361(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 39) {
        s1 = peg$c272;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c273); }
      }
      if (s1 as any !== peg$FAILED) {
        s2 = [];
        s3 = peg$parsesingleQuoteStringChar();
        while (s3 as any !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parsesingleQuoteStringChar();
        }
        if (s2 as any !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 39) {
            s3 = peg$c272;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c273); }
          }
          if (s3 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c361(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c358); }
    }

    return s0;
  }

  function peg$parsedoubleQuoteStringChar(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 34) {
      s2 = peg$c359;
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c360); }
    }
    if (s2 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 92) {
        s2 = peg$c362;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c363); }
      }
    }
    peg$silentFails--;
    if (s2 as any === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c364); }
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c365(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 92) {
        s1 = peg$c362;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c363); }
      }
      if (s1 as any !== peg$FAILED) {
        s2 = peg$parseescapeSequence();
        if (s2 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c366(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsesingleQuoteStringChar(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 39) {
      s2 = peg$c272;
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c273); }
    }
    if (s2 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 92) {
        s2 = peg$c362;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c363); }
      }
    }
    peg$silentFails--;
    if (s2 as any === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c364); }
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c365(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 92) {
        s1 = peg$c362;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c363); }
      }
      if (s1 as any !== peg$FAILED) {
        s2 = peg$parseescapeSequence();
        if (s2 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c366(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseescapeSequence(): any {
    let s0, s1, s2, s3, s4, s5, s6;

    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 39) {
      s0 = peg$c272;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c273); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 34) {
        s0 = peg$c359;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c360); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 92) {
          s0 = peg$c362;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c363); }
        }
        if (s0 as any === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 116) {
            s1 = peg$c368;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c369); }
          }
          if (s1 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c370();
          }
          s0 = s1;
          if (s0 as any === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 110) {
              s1 = peg$c371;
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c372); }
            }
            if (s1 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c373();
            }
            s0 = s1;
            if (s0 as any === peg$FAILED) {
              s0 = peg$currPos;
              if (input.charCodeAt(peg$currPos) === 114) {
                s1 = peg$c374;
                peg$currPos++;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c375); }
              }
              if (s1 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c376();
              }
              s0 = s1;
              if (s0 as any === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 117) {
                  s1 = peg$c377;
                  peg$currPos++;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c378); }
                }
                if (s1 as any !== peg$FAILED) {
                  s2 = peg$currPos;
                  if (peg$c379.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c380); }
                  }
                  if (s3 as any !== peg$FAILED) {
                    if (peg$c379.test(input.charAt(peg$currPos))) {
                      s4 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s4 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c380); }
                    }
                    if (s4 as any !== peg$FAILED) {
                      if (peg$c379.test(input.charAt(peg$currPos))) {
                        s5 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c380); }
                      }
                      if (s5 as any !== peg$FAILED) {
                        if (peg$c379.test(input.charAt(peg$currPos))) {
                          s6 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s6 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c380); }
                        }
                        if (s6 as any !== peg$FAILED) {
                          s3 = [s3, s4, s5, s6];
                          s2 = s3;
                        } else {
                          peg$currPos = s2;
                          s2 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s2;
                        s2 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s2;
                      s2 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s2;
                    s2 = peg$FAILED;
                  }
                  if (s2 as any !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c381(s2);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c367); }
    }

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

export interface ParseOptions {
  filename?: string;
  startRule?: string;
  tracer?: any;
  [key: string]: any;
}
export type ParseFunction = (input: string, options?: ParseOptions) => any;
export const parse: ParseFunction = peg$parse;

